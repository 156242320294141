/* eslint-disable */
import {BaseType} from "./BaseType";

class Urway extends BaseType {
    /**
     * Init the payment.
     * @param configurations
     * @returns {Promise<*>}
     */
    async initPayment(configurations = {}) {
        let axios = await this.getAxios();

        let {initiate} = this.sdk.info;
        return await axios[initiate.method](initiate.path, this.sdk.withConfig(configurations));
    }

    /**
     * Transform init payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, is_live: {}|*|undefined, gateway: {}|*|undefined}}
     */
    initPaymentBuildResponse(success = true, payload = {}) {
        let gateway = this.sdk.getConfig('payment_gateway', payload && payload['gateway'] || undefined);
        // let is_live = this.sdk.getConfig('is_live', 0);

        let results = {
            success, gateway, //is_live,
        };

        if (success) {
            results = {
                ...results, url: payload['checkout_url'] || ""
            }
        } else {
            if ("response" in payload && "data" in payload.response) {
                payload = payload.response['data'] || {};
            }
            results = {
                ...results,
                errors: [],
                message: payload['message'] || ""
            }
        }

        return results;
    }

    /**
     * Register payment response.
     * @param body
     * @returns {Promise<*>}
     */
    async registerPayment(body = {}) {
        let axios = await this.getAxios();
        
        return this.sdk.loadInfo()
            .then(info => {
                let {register} = info;

                body.track_id = body.TrackId;
                body.tran_id = body.TranId;
                body.response_hash = body.responseHash;
                body.response_code = body.ResponseCode;
                let config = this.sdk.withConfig(body);
                let newBody = {
                    "project": config.project,
                    "payment_gateway": config.payment_gateway,
                    "payment_gateway_type": config.payment_gateway_type,
                    "is_live": config.is_live,
                    "tran_id": config.TranId,
                    "response_code": config.ResponseCode,
                    "amount": config.amount,
                    "track_id" : config.TrackId,
                    "response_hash":config.responseHash,
                    "currency": config.currency
                };
                return axios[register.method](register.path, newBody);
            })
    }

    /**
     * Transform register payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, gateway: {}|*|undefined}}
     */
    registerPaymentBuildResponse(success = true, payload = {}) {
        let {gateway, message, data, response} = payload;
        gateway = gateway || this.sdk.getConfig('payment_gateway');
        response = response || {};
        data = data || response['data'] || {};
        message = message || data['message'] || "";
        let errors = [];

        let results = {
            success, gateway,
        };

        if (success) {
            results = {
                ...results, data
            }
        } else {
            if (response && 'data' in response) {
                data = response['data'] || {};

                if (data && 'data' in data) {
                    data = data['data'] || {};
                }

                if (data && 'message' in data) {
                    message = data['message'] || "";
                }

                errors = data && data['errors'] || [];
                message = data && data['message'] || message;
            }

            if (message && typeof message === 'object' && 'message' in message) {
                message = message['message'] || "";
            }

            results = {
                ...results,
                errors,
                message
            }
        }

        return results;
    }
}

const $exports = {
    async make(sdk, config = {}) {
        await sdk.init(config);
        return new Urway(sdk);
    }
};

export default $exports
